header {
    padding: 20px;
}

.logo {
    width: 120px;
}

.main-heading {
    margin-left: 20px;
    font-size: 36px;
}

.header-title {
    display: flex;
    align-items: center;
}

ul {
    list-style-type: none;
}

li {
    margin-left: 5px;
}

nav {
    flex: 1;
}

.nav-header {
    padding: 1rem;
}

.nav-toggle {
    font-size: 1.5rem;
    background: transparent;
    border-color: transparent;
    transition: all 0.3s linear;
    cursor: pointer;
}

.nav-toggle:hover {
    background-color: #32e0c426;
    transform: rotate(90deg);
}

.links a {
    color: #324D67;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    display: block;
    padding: 0.5rem 1rem;
    transition: all 0.3s linear;
    text-decoration: none;
}

.links a:hover {
    background-color: #32e0c426;
    color: #49a6e9;
    padding-left: 1.5rem;
}

.links-container {
    height: 0;
    overflow: hidden;
    transition: all 0.3s linear;
}

.link-icon {
    display: none;
}

@media screen and (max-width: 799px) {
    header {
        flex-wrap: wrap;
    }
    
    .logo {
        width: 80px;
    }

    h1 {
        font-size: 1.5rem;
    } 
}

@media screen and (min-width: 1000px) {
    header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .nav {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav-header {
        padding: 0;
    }

    .nav-toggle {
        display: none;
    }

    .links-container {
        height: auto !important;
    }

    .links {
         display: flex;
    }

    .links a {
        padding: 0;
        margin: 0 0.5rem;
    }

    .links a:hover, .link-icon:hover {
        padding: 0;
        background: transparent;
    }
}

@media screen and (min-width: 1100px) {
    .link-icon {
        display: inline;
        width: 25px;
        height: auto;
        margin-right: 5px;
        margin-bottom: -5px;
    }
}