.projects {
    margin-bottom: 20px;
}

.projects-container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.project-card{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 20px 0px;
    padding: 30px;
    background-color: #e2e1e4;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.project-info {
    margin-left: 20px;
}

.project-img {
    width: 400px;
    height: 250px;
    margin-right: 20px;
    flex-shrink: 0;
}

.project-tags{
    display: flex;
    flex-wrap: wrap;
}

.tag {
    padding:  8px;
    background-color: #0095ff;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 4px;
}

.project-title{
    font-size: 20px;
    font-weight: 600;
}

a {
    text-decoration: none;
    color: #000000;
}

.link-button {
    width: max-content;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 16px;
    color: #324d67;
    margin-bottom: 10px;
    margin-left: -12px;
}

.link-button:hover {
    color: #49a6e9;
}

@media screen and (max-width: 899px){
    .project-card {
        flex-direction: column;
    }

    .project-img {
        align-self: center;
    }
}

@media screen and (max-width: 499px){
    .project-img {
        width: 90%;
        height: 150px;
    }
}