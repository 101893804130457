.about {
    margin: 40px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.resume-button {
    font-size: 16px;
    color: #ffffff;
    padding: 15px 30px;
    margin: 20px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #5C7CFA;
    border: 1px solid #ccc;
    text-transform: uppercase;
    font-weight: 600;
}

.resume-button:hover {
    background-color: #132568;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#profile-player {
    width: 450px;
}

.social-contact {
    display: flex;
    margin: 30px auto;
    justify-content: center;
}

.icon-container {
    width: 90px;
    height: 90px;
    margin: auto 5px;
}

.social-icon {
    width: 80%;
    height: auto;
}

.social-icon:hover {
    width: 100%;
    height: auto;
}

.about-info {
    font-size: 16px;
    line-height: 25px;
}

.info-name {
    color: #5C7CFA;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
}

.job-title {
    font-weight: 400;
    font-size: 25px;
    line-height: 50px;
}

@media screen and (max-width: 899px) {
    .about {
        flex-direction: column;
    }

    .profile {
        padding: 0 10%;
        background-image: url("../../assets/images/profile-background.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media screen and (max-width: 499px) {
    #profile-player {
        width: 80%;
    }

    .social-contact {
        width: 100%;
    }

    .icon-container {
        width: 20%;
    }
}

@media screen and (min-width: 900px) {
    .profile {
        max-width: 50%;
    }

    .about {
        background-image: url("../../assets/images/profile-background.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 60px;
        padding-top: 60px;
        padding-bottom: 20px;
    }
}