.contact-me {
    margin-bottom: 0;
}

.contact-container {
    display: flex;
    justify-content: space-evenly;
    padding: 60px 0;
}

input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 6px;
    font-family: "Verdana";
    font-size: 14px;
}

textarea {
    height: 120px;
}

input[type=submit] {
    background-color: #5C7CFA;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}

input[type=submit]:hover {
    background-color: #132568;
}

.feedback {
    background-color: #32e0c426;
    border-radius: 4px;
    padding: 12px;
    box-sizing: border-box;
}

form {
    width: 320px;
    padding: 40px;
    background-color: #e2e1e4;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.feedback-container {
    width: fit-content;
    padding: 20px;
    background-color: #f0ffff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 5;
}

#send-player {
    width: 100%;
}

.darkBG {
    background-color: #00000033;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
}

.close-button {
    align-self: flex-end;
    width: 120px;
    height: 120px;
    padding: 0;
    background-color: none;
}

.close-img:hover {
    width: 110px;
    height: auto;
    cursor: pointer;
}

.animation-container {
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/images/contact-background.svg");
    background-position-y: 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    max-width: 40%;
    align-self: center;
}

#mail-player {
    width: 40%;
}

#contact-player {
    width: 80%;
}

.form-icon {
    width: 20px;
    margin-right: 6px;
    margin-bottom: -3px;
}

@media screen and (max-width: 799px) {
    .animation-container {
        width: 90%;
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 399px) {
    form {
        width: 90%;
        padding: 40px 10px;
    }

    .feedback-container {
        width: 80%;
    }
}

@media screen and (min-width: 1600px) {
    .animation-container {
        width: 600px;
    }
}
